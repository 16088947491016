import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'

interface DialogHeaderProps {
  onClickClose: () => void
  title?: string
  subtitle?: string
  overtitle?: string
}

const DialogHeader: React.FC<React.PropsWithoutRef<DialogHeaderProps>> = ({
  onClickClose,
  title,
  subtitle,
  overtitle,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
      }}
    >
      <Box>
        <Typography sx={{ color: 'text.secondary', fontSize: '14px' }}>
          {overtitle}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontSize: '20px',
            mb: 1,
          }}
        >
          {title}
        </Typography>
        <Typography sx={{ color: 'text.secondary', fontSize: '14px' }}>
          {subtitle}
        </Typography>
      </Box>

      <IconButton onClick={onClickClose}>
        <CloseIcon style={{ color: '#11181C' }} />
      </IconButton>
    </Box>
  )
}

export default DialogHeader
